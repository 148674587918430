import { Controller } from "@hotwired/stimulus";

// Reference: https://codepen.io/dixie0704/pen/jOVxGXL
// Inline the tailwind code
export default class extends Controller {
  static targets = ["optionsContainer", "display", "value", "option", "icon"];
  static selectedIndex;

  connect() {
    this.selectedIndex = -1;
    if (this.valueTarget.value !== "") {
      const opt = this.displayedOptions().find((option) => option.dataset.value === this.valueTarget.value);
      if (opt) {
        this.selectedIndex = this.displayedOptions().indexOf(opt);
        this.selectOption(this.displayedOptions()[this.selectedIndex]);
      }
    }
  }

  // Actions
  show() {
    this.displayTarget.value = "";
    this.open();
  }

  blur() {
    let validOption = false;
    if (this.displayTarget.value !== "") {
      this.displayedOptions().forEach((option) => {
        if (option.dataset.name === this.displayTarget.value) {
          validOption = true;
        }
      });
    } else {
      validOption = true;
    }

    if (validOption && this.selectedIndex >= 0) {
      let option = this.displayedOptions()[this.selectedIndex];
      if (option) {
        this.displayTarget.value = this.displayedOptions()[this.selectedIndex]?.dataset?.name;
      }
    } else {
      this.clearSelection();
    }

    this.close();
  }

  toggle() {
    if (this.optionsContainerTarget.classList.contains("tw-hidden")) {
      this.show();
    } else {
      this.blur();
    }
  }

  nextOption(e) {
    e.preventDefault();

    if (this.optionsContainerTarget.classList.contains("tw-hidden")) {
      this.open();
      return;
    }

    this.unhighlightOption();

    this.selectedIndex++;
    if (this.selectedIndex >= this.displayedOptions().length) {
      this.selectedIndex = 0;
    }
    this.highlightOption();

    this.displayedOptions()[this.selectedIndex].scrollIntoView({ behavior: "smooth", block: "nearest" });
  }

  previousOption(e) {
    e.preventDefault();

    if (this.optionsContainerTarget.classList.contains("tw-hidden")) {
      this.open();
      return;
    }

    this.unhighlightOption();

    this.selectedIndex--;
    if (this.selectedIndex < 0) {
      this.selectedIndex = this.optionsContainerTarget.children.length - 1;
    }
    this.highlightOption();

    this.displayedOptions()[this.selectedIndex].scrollIntoView({ behavior: "smooth", block: "nearest" });
  }

  suppressBlur(e) {
    // This is a workaround for the fact that the blur event is fired before the click event
    e.preventDefault();
  }

  keyboardSelect(e) {
    e.preventDefault();

    this.unhighlightOption();

    let targetElement = this.displayedOptions()[this.selectedIndex];
    if (targetElement) {
      this.selectOption(targetElement)
      this.close();
    }

    this.dispatchChange(targetElement);
  }

  clickSelect(e) {
    e.preventDefault();
    this.unhighlightOption();

    let targetElement = e.currentTarget;
    this.selectOption(targetElement)

    this.close();

    this.dispatchChange(targetElement);
  }

  // Change is dispatched any time the selected option changes but not when
  // the dropdown is first initialized.
  dispatchChange = (targetElement) => {
    this.dispatch("change", {
      detail: {
        field_name: this.element.dataset.fieldName,
        container_id: this.element.id,
        value: targetElement.dataset.value,
        name: targetElement.dataset.name
      }
    });
  }

  // Helpers
  open() {
    this.optionsContainerTarget.classList.remove("tw-hidden");
  }

  close() {
    this.optionsContainerTarget.classList.add("tw-hidden");
  }

  clearSelection() {
    this.displayTarget.value = "";
    this.valueTarget.value = "";
    this.selectedIndex = -1;
    this.optionTargets.forEach((option) => {
      option.dataset.selected = false;
      option.classList.remove("active");
      option.classList.remove("tw-hidden");
      option.classList.remove("tw-bg-blue-grey-100");
    });
    this.unhighlightOption();
  }

  selectOption(option) {
    this.clearSelection();

    this.selectedIndex = this.displayedOptions().indexOf(option);
    this.displayTarget.value = option.dataset.name;
    this.valueTarget.value = option.dataset.value;
    option.dataset.selected = true;
    option.classList.add("active");

    this.dispatch("selected", {
      detail: {
        field_name: this.element.dataset.fieldName,
        container_id: this.element.id,
        value: option.dataset.value,
        name: option.dataset.name
      }
    });

    this.highlightOption();
  }

  unhighlightOption() {
    if (this.selectedIndex >= 0) {
      this.displayedOptions()[this.selectedIndex].classList.remove("tw-bg-blue-grey-100");
    }
  }

  highlightOption() {
    // Don't add the light highlight if the item is already selected
    const option = this.displayedOptions()[this.selectedIndex];
    if (option && !option.classList.contains("active")) {
      option.classList.add("tw-bg-blue-grey-100");
    }
  }

  displayedOptions() {
    return this.optionTargets.filter((option) => !option.classList.contains("tw-hidden"));
  }
}
